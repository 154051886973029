
















































































































































































































import useAlert from '@/use/alert'
import useService from '@/use/service'
import useNumber from '@/use/number'
import { defineComponent, onMounted, reactive, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'
import useString from '@/use/string'

export default defineComponent({
  setup(_, { root }) {
    const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

    const {
      alertData,
      getAlertType,
      getAlertTypeIcon,
      getAlertTimeLeft,
      getAlertStatus,
      getAlertStatusColor,
      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileage,
      getAlertVehicle,
      getAlertCompany,
    } = useAlert()


    const { formatService } = useService()
    const { formatNumber } = useNumber()
    const { nl2br } = useString()

    const state = reactive({
      error: false,
      loading: false,
      alert: null as any
    })

    const model = reactive({
      status: null
    })

    const fetch = () => {
      state.loading = true

      axiosInstance
        .get(`alert/${root.$route.params.id}`)
        .then(({ data }) => {
          state.alert = data
          model.status = data.status
          state.error = false
        })
        .catch(() => {
          state.error = true
        })
        .finally(() => {
          state.loading = false
        })
    }

    onMounted(fetch)

    const updateStatus = () => {
      state.loading = true

      axiosInstance
        .put(`alert/${root.$route.params.id}`, { status: model.status })
        .then(fetch)
        .catch(() => {
          state.error = true
        })
        .finally(() => {
          state.loading = false
        })
    }

    watch(() => model.status, updateStatus)

    return {
      alertData,
      getAlertType,
      getAlertTypeIcon,
      getAlertTimeLeft,
      getAlertStatus,
      getAlertStatusColor,
      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileage,
      getAlertVehicle,
      getAlertCompany,

      formatService,
      formatNumber,
      nl2br,

      state,
      model
    }
  }
})
